export function getSubject(type) {
	let subject, previewText;

	if (type === 'welcomeEmail' || type === 'confirmEmail') {
		subject = 'Подтвердите адрес электронной почты для GoBnB';
		previewText = 'Action Required! Confirm your e-mail address';
	}
	if (type === 'bookingRequest') {
		subject = 'У вас новое бронирование';
		previewText = 'Great News! You have a new reservation';
	}
	if (type === 'bookingRequestGuest') {
		subject = 'Ваш запрос на бронирование отправлен хозяину жилья ';
		previewText = 'Great News! Your reservation is shared with host';
	}
	if (type === 'bookingConfirmedToHost') {
		subject = 'Вы подтвердили бронирование';
		previewText = 'Confirmed Reservation Details';
	}
	if (type === 'bookingConfirmedToGuest') {
		subject = 'Ваше бронирование подтверждено вашим хозяином';
		previewText = 'Pack your bag, get ready for your trip.';
	}
	if (type === 'bookingDeclinedToGuest') {
		subject = 'Ваш запрос на бронирование отклонен хозяином жилья';
		previewText = "It's unfortunate to inform you that your reservation request has been declined";
	}
	if (type === 'bookingExpiredGuest') {
		subject = 'Срок действия вашего запроса на бронирование истек';
		previewText = "It's unfortunate to inform you that your reservation request has been expired";
	}
	if (type === 'bookingExpiredHost') {
		subject = 'Срок действия вашего бронирования истек';
		previewText = "It's unfortunate to inform you that your reservation has been expired";
	}
	if (type === 'cancelledByHost') {
		subject = 'Ваше бронирование отменено хозяином';
		previewText = "It's unfortunate to inform you that your booking has been cancelled";
	}
	if (type === 'cancelledByGuest') {
		subject = 'Ваше бронирование отменено гостем';
		previewText = "It's unfortunate to inform you that your reservation has been cancelled";
	}
	if (type === 'completedGuest') {
		subject = 'Пожалуйста, напишите отзыв для хозяина жилья ';
		previewText = "Greetings! It's great to have a review on your stay experience";
	}
	if (type === 'completedHost') {
		subject = 'Пожалуйста, напишите отзыв для вашего гостя';
		previewText = "Greetings! It's great to have a review on your reservation experience";
	}
	if (type === 'forgotPasswordLink') {
		subject = 'Заявка на изменение пароля';
		previewText = 'Action Required! Reset your Password using the link shared';
	}
	if (type === 'message') {
		subject = 'У вас есть новое сообщение';
		previewText = 'Someone sent you a new message!';
	}

	if (type === 'inquiry') {
		subject = 'У вас новый запрос';
		previewText = 'Someone sent you an inquiry from contact form!';
	}

	if (type === 'documentVerification') {
		subject = 'Статус верификации документов';
		previewText = 'Documents verification status';
	}
	if (type === 'contact') {
		subject = 'Вы получили уведомление от службы поддержки клиентов';
		previewText = 'You got a customer support notification';
	}
	if (type === 'reportUser') {
		subject = 'Вы получили уведомление о нарушении пользователя';
		previewText = 'You have received a notification regarding user violation';
	}
	if (type === 'bookingPreApproval') {
		subject = 'Хозяин предварительно одобрил ваш запрос';
		previewText = 'Greetings! Your pre-approval request has been accepted by the host';
	}

	if (type === 'banStatusServiceStatusBanned') {
		subject = 'Ваша учетная запись заблокирована';
		previewText = "It's unfortunate to inform you that your account has been disabled";
	}
	if (type === 'banStatusServiceStatusUnBanned') {
		subject = 'Ваша учетная запись разблокирована ';
		previewText = "Greetings! It's fortunate to inform you that your account has been enabled";
	}
	if (type === 'contactSupport') {
		subject = 'You have got a customer support notification';
		previewText = 'You have got a customer support notification';
	}
	if (type === 'userFeedback') {
		subject = 'Вы получили уведомление об обратной связи с клиентами';
		previewText = 'You have got a Customer Feedback notification';
	}
	if (type === 'listPublishRequest') {
		subject = 'У вас есть новое жилье для утверждения';
		previewText = 'You have new listing submission for approval';
	}
	if (type == 'adminListApprove') {
		subject = 'Ваша заявка на публикацию одобрена';
		previewText = 'Greetings! Your listing submission has been approved';
	}
	if (type == 'adminListReject') {
		subject = 'Ваша заявка на публикацию жилья отклонена';
		previewText = "It's unfortunate to inform you that your listing submission has been rejected";
	}

	return {
		subject,
		previewText
	};
}